
import {
  ref,
  computed,
  getCurrentInstance,
  onMounted,
  onUpdated,
  watch,
} from "vue";
import arraySort from "array-sort";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
}

interface IHeaderConfiguration {
  column?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

export default {
  name: "TableBasic",
  emit: ["current-change", "sort", "items-per-page-change"],
  props: {
    headers: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 1,
    },
    rowsPerPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    order: {
      type: String,
      default: "asc",
    },
    sortLabel: {
      type: String,
      default: "",
    },
    goTo: {
      type: String,
      default: "",
    },
  },

  setup(props, { emit }) {
    const currentSort = ref<string>("");
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const vnodeProps = getCurrentInstance()?.vnode.props || {};
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: props.rowsPerPage,
      total: props.total,
    });
    const editButton = computed(
      () => props.buttons.filter((x) => x.type === "EDIT").length > 0
    );
    const removeButton = computed(
      () => props.buttons.filter((x) => x.type === "REMOVE").length > 0
    );

    watch(props.data, () => {
      if ("onCurrentChange" in vnodeProps) {
        currentSort.value = label.value + order.value;
      }
    });

    const sort = (columnName, sortable) => {
      if (sortable === false) {
        return;
      }
      if ("onSort" in vnodeProps) {
        if (order.value === "asc") {
          order.value = "desc";
          emit("sort", { columnName: columnName, order: "desc" });
        } else {
          order.value = "asc";
          emit("sort", { columnName: columnName, order: "asc" });
        }
      } else {
        if (order.value === "asc") {
          order.value = "desc";
          arraySort(props.data, columnName, { reverse: false });
        } else {
          order.value = "asc";
          arraySort(props.data, columnName, { reverse: true });
        }
      }
      currentSort.value = columnName + order.value;
    };

    const getItems = computed(() => {
      const clone = JSON.parse(JSON.stringify(props.data));
      const startFrom =
        pagination.value.page * pagination.value.rowsPerPage -
        pagination.value.rowsPerPage;
      return clone.length
        ? clone.splice(startFrom, pagination.value.rowsPerPage)
        : clone;
    });

    onMounted(() => {
      currentSort.value = label.value + order.value;
      pagination.value.rowsPerPage = props.rowsPerPage;
    });

    onUpdated(() => {
      pagination.value.total = props.data.length;
    });

    const currentPageChange = (val) => {
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.value.page = val;
      }
    };

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.value.rowsPerPage = parseInt(event.target.value);
      }
    };

    return {
      editButton,
      removeButton,
      getItems,
      pagination,
      sort,
      currentPageChange,
      setItemsPerPage,
    };
  },
};
