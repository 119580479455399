import { createI18n } from "vue-i18n/index";

const messages = {
  en: {
    language: "Language",
    en: "English",
    es: "Spanish",
    // menu
    home: "Home",
    dashboard: "Dashboard",
    layoutBuilder: "Settings Layout",
    usersgroupIndex: "Users group",
    user: "Users",
    profile: "my Profile",
    profileOverview: "Overview",
    maritimeFileOverview: "Overview",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    permissionsIndex: "Permissions",
    usersIndex: "Users management",
    userCreate: "New user",
    userUpdate: "Edit user",
    companies: "Companies",
    companiesIndex: "Companies management",
    companiesCreate: "New company",
    companiesUpdate: "Edit company",
    companiesGroup: "Group of companies",
    companiesGroupIndex: "Management group of companies",
    companiesGroupCreate: "New group of companies",
    companiesGroupUpdate: "Edit group of companies",
    offices: "Offices",
    officesIndex: "Offices management",
    officesCreate: "New office",
    officesUpdate: "Edit office",
    maritime_agency: "Maritime agency",
    maritime_files: "Maritime files",
    maritime_file: "Files",
    maritime_trips: "Trips",
    maritime_trip: "Trip",
    measures_unit: "Measure units",
    measures_type: "Measure types",
    measure_unit_type: "Measure Unit types",
    address_type: "Address types",
    ports: "Ports",
    docks: "Docks",
    contacts_type: "Contacts types",
    routes_type: "Routes types",
    equipments: "Equipments",
    equipments_type: "Equipment type",
    equipments_subtype: "Equipment subtype",
    sectors_type: "Sectors type",
    transport_order_status: "Transport order status",
    transport_order_type: "Transport order type",
    maritime_file_type: "Maritime file type",
    maritime_file_operations: "Maritime Files Operations",
    media_type: "Communication media type",
    location_type: "Locations",
    bookings: "Bookings",
    bills_of_lading: "Bills of lading",
    type_entities: "Entities Type",
    goods_type: "Goods Type",
    entities: "Entities",
    catalogs: "Catalogs",
    id: "Id",
    smpurposes: "Ship mooring purposes",
    smrequirements: "Ship mooring Requirements",
    smoperation_typesmoperation_type: "Operations types",
    // end menu
    //buttons
    btnSave: "Save",
    btnEdit: "Edit",
    btnNew: "New",
    btnDelete: "Delete",
    btnCancel: "Cancel",
    btnAdd: "Add",
    wait: "Please wait...",
    btnDetails: "See detail",
    loading: "Loading",
    //end buttons
    //inputs
    iprofile: "Profile",
    iemail: "Email",
    ipassword: "Password",
    iassignoffices: "Assign offices",
    imeasureunittype: "MU type",
    iname: "Name",
    isize: "Size",
    itemperature: "Temperature",
    imanufacturing_materials: "Manufacturing materials",
    icoordinates: "Coordinates",
    icountry: "Country",
    isubdivision: "Subdivision",
    ifullcode: "Full Code",
    ifullname: "Full Name",
    iiso: "ISO",
    imaritime: "Maritime",
    imaritimefile: "Maritime file",
    iborndate: "Born date",
    iid: "ID",
    iaddress: "Address",
    ifiscaladdress: "Fiscal address",
    iphone: "Phone",
    ifax: "Fax",
    icif: "NIF/CIF",
    icompany: "Company",
    icompaniesgroup: "Companies group",
    ientity: "Entity",
    ientityreference: "Entity reference",
    ientitydata: "Entity data",
    iwebsite: "Website",
    istatus: "Status",
    istatusactive: "Active",
    istatuscancel: "Cancel",
    icurrentpass: "Current Password",
    inewpass: "New Password",
    iconfirmnewpass: "Confirm New Password",
    iupdatepass: "Update Password",
    icode: "Code",
    inote: "Note",
    ifile: "File",
    ifileType: "File Type",
    ioffices: "Office",
    iport: "Port",
    ivessel: "Vessel",
    ishipConsignne: "Ship Consignne",
    iconsignne: "Consignee",
    icustomer: "Customer",
    icontact: "Contact",
    icheckin: "Checkin",
    icheckout: "Checkout",
    ioperation: "Operation",
    iprefix: "Prefix",
    icalls: "Calls",
    iimport: "Import",
    iexport: "Export",
    itrip: "Trip",
    itripType: "Trip type",
    iremarks: "Remarks",
    ishipowner: "Shipowner",
    ibookingNumber: "Number booking",
    ibookingDate: "Booking date",
    ibookingref: "Reference",
    ishipper: "Shipper",
    ishipperAddress: "Shipper address",
    iActions: "Actions",
    iBooking: "Booking",
    iDescription: "Description",
    iparticipantType: "Participant type",
    itype: "Type",
    isection: "Section",
    iinitialBL: "Initial BL",
    ifinalBL: "Final BL",
    iinitialDeparture: "Initial departure",
    ifinalDeparture: "Final departure",
    ibaseSection: "Base section",
    isectionIdentifier: "Section identificator",
    ismrequirement: "Requirement",
    ismpurpose: "Purpose",
    ismoperationType: "Operation type",
    igoodsType: "Goods Type",
    istation: "Station",
    inodata: "No data",
    isearch: "Search",
    ielements: "element(s)",
    iconcession: "Concession",
    izone: "Zone",
    ialignment: "Alignment",
    iselect: "Select an element",
    iselectdate: "Select a date",
    ilegalname: "Legal name",
    iidentificationtype: "Identification type",
    iidentification: "Identification",
    ilogo: "Logo",
    imanufacturingmaterials: "Manufacturing materials",
    iequipmenttype: "Equipment type",
    iportauthority: "Port authority",
    icustomimport: "Custom import",
    icustomexport: "Custom export",
    iaddressrouteedi: "Address route edi",
    icolor: "Color",
    iorder: "Order",
    idate: "Date",
    iblid: "BL id",
    icontainer: "Container",
    iregistercontainer: "Register container",
    igoods: "Goods",
    iimo: "Imo",
    iregisterequipment: "Registered equipment",
    iidshipload: "Shipload Id",
    //end inputs
    //validate
    rpassword: "Password must be at least 4 character and contain symbols",
    rpasswordrequired: "Password is a required field",
    rname: "Name is a required field",
    rmeasureunittypeid: "Measure unit type is a required field",
    rDesc: "Description is a required field",
    rRemarks: "Remarks is a required field",
    rcode: "Code is a required field",
    rshipownerid: "Shipowner is a required field",
    rtriptype: "Trip type is a required field",
    rconsigneeid: "Consignee is a required field",
    rtype: "Consignee type is a required field",
    rbasesection: "Base Section is a required field",
    rsmrequirement: "Requirement is a required field",
    rsmpurpose: "Purpose is a required field",
    rdock: "Dock is a required field",
    rcheckin: "Checkin is a required field",
    rcheckout: "Checkout is a required field",
    roperationtype: "Operation type is a required field",
    rstationid: "Station is a required field",
    rgoodstypeid: "Goods type is a required field",
    rofficeid: "Office is a required field",
    rportid: "Port is a required field",
    raddresstype: "Address type is a required field",
    rshipid: "Vessel is a required field",
    rmedia: "Communication media type is a required field",
    rcontacttype: "Contact type is a required field",
    rcontact: "Contact is a required field",
    rcountry: "Country is a required field",
    rentitytype: "Entity type is a required field",
    requipmenttype: "Equipment type is a required field",
    requipmentsubtype: "Equipment subtype is a required field",
    requipment: "Equipment is a required field",
    rlocation: "Location is a required field",
    rmaritimefilesoperations: "Maritime files operations is a required field",
    rmaritimefilestype: "Maritime files type is a required field",
    rservice: "Maritime service is a required field",
    rmeasuretypeid: "Measure type is a required field",
    rparticipant: "Participant type is a required field",
    rroute: "Route type is a required field",
    rsection: "Sector type is a required field",
    rtransportorderstatus: "Transport order status is a required field",
    rtransportordertype: "Transport order type is a required field",
    riso: "ISO is a required field",
    rmaritime: "Maritime is a required field",
    ruser: "User is a required field",
    rcustomer: "Customer is a required field",
    rentity: "Field is required",
    rmaritimevoyage: "Maritime voyage is required",
    rmteditabletable: "To add a new trip, the data in the table must be valid",
    rgceditabletable:
      "To add a new good consignee, the data in the table must be valid",
    rsmeditabletable:
      "To add a new ship mooring, the data in the table must be valid",
    rmoeditabletable:
      "To add a new mooring operation, the data in the table must be valid",
    rmaritimefiletables:
      "To submit a new maritime file, there must be at least one element of: trips, good consignees, mooring and mooring operations",
    rbkeditabletable:
        "To submit a new booking, the data in the table must be valid",
    rtseditabletable:
        "To submit a new transshipment, the data in the table must be valid",
    rbreditabletable:
        "To submit a new booking route, the data in the table must be valid",
    rpeditabletable:
        "To submit a new participant, the data in the table must be valid",
    rbleditabletable:
        "To submit a new bill of landing, the data in the table must be valid",
    rsleditabletable:
        "To submit a new shipload, the data in the table must be valid",
    rgfeditabletable:
        "To submit a new good feature, the data in the table must be valid",
    rbooking:
        "To submit a new booking, there must be at least one element of: booking, transshipment, booking route, participant and bill of landing",
    //end validate
    //labels
    lpermisionsassigned: "Permissions assigned",
    //end labels
    //titles
    timaritimefilelist: "Maritime files",
    timaritimetripslist: "Maritime voyages",
    tisummarystatement: "Summary statement",
    timaritimefile: "Maritime file",
    titrips: "Voyages",
    tibookings: "Booking",
    tcalls: "Calls",
    troute: "Routes",
    tmoorings: "Ship Moorings",
    tconsignees: "Goods Consignees",
    tmooringoperation: "Ship Mooring Operation",
    tiBillOfLadings: "Bill of ladings",
    tiEntityTypes: "Entity Types",
    toperations: "Operations",
    ttransshipment: "Transshipment",
    tbookingroute: "Booking routes",
    tparticipants: "Participants",
    tgoodsfeatures: "Goods Features",
    tshipsloads: "Shiploads",
    //endtitles
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signOut: "Sign Out",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    activity: "Activity",
    createNewBusinessGroup: "Create new company group",
    createNewOfficeGroup: "Create new office group",
    createNewBusiness: "Create new company",

    //modal titles
    mt_maritime_file_add: "Add maritime file",
    mt_maritime_file_edit: "Edit maritime file",
    mt_address_type_add: "Add address type",
    mt_address_type_edit: "Edit address type",
    mt_port_add: "Add port",
    mt_port_edit: "Edit port",
    mt_docks_add: "Add docks",
    mt_docks_edit: "Edit docks",
    mt_measure_type_add: "Add measure type",
    mt_measure_type_edit: "Edit measure type",
    mt_measure_unit_edit: "Edit measure unit",
    mt_measure_unit_add: "Add measure unit",
    mt_measure_type_unit_edit: "Edit measure type unit",
    mt_measure_type_unit_add: "Add measure type unit",
    mt_contact_type_edit: "Edit contact type",
    mt_contact_type_add: "Add contact type",
    mt_transport_order_status_edit: "Edit transport order status",
    mt_transport_order_status_add: "Add transport order status",
    mt_transport_order_type_edit: "Edit transport order type",
    mt_transport_order_type_add: "Add transport order type",
    mt_route_type_edit: "Edit route type",
    mt_route_type_add: "Add route type",
    mt_equipment_type_edit: "Edit equipment type",
    mt_equipment_type_add: "Add equipment type",
    mt_equipment_subtype_edit: "Modificar subtipo de equipamiento",
    mt_equipment_subtype_add: "Agregar subtipo de equipamiento",
    mt_sector_type_edit: "Edit sector type",
    mt_sector_type_add: "Add sector type",
    mt_maritime_file_operation_edit: "Edit maritime file operation",
    mt_maritime_file_operation_add: "Add maritime file operation",
    mt_media_edit: "Edit communication media type",
    mt_media_add: "Add communication media type",
    mt_location_edit: "Edit location",
    mt_location_add: "Add location",
    mt_entity_edit: "Edit entity type",
    mt_entity_add: "Add entity type",
    mt_goods_type_edit: "Edit goods type",
    mt_goods_type_add: "Add goods type",
    mt_smpurposes_edit: "Edit ship moorign purpose",
    mt_smpurposes_add: "Add ship moorign purpose",
    mt_smrequirements_edit: "Edit ship mooring requirements",
    mt_smrequirements_add: "Add ship mooring requirements",
    mt_equipment_edit: "Edit equipment",
    mt_equipment_add: "Add equipment",
    mt_add_mf_booking_route: "Add route to booking",
    mt_add_mf_booking_equipment: "Add equipment to booking",
    mt_maritime_trip_edit: "Edit maritime trip",
    mt_maritime_trip_add: "Add maritime trip",
    mt_good_consignee_edit: "Edit consignee",
    mt_good_consignee_add: "Add consignee",
    mt_ship_mooring_edit: "Edit ship mooring",
    mt_ship_mooring_add: "Add ship mooring",
    mt_mooring_operation_edit: "Edit mooring operation",
    mt_mooring_operation_add: "Add mooring operation",
  },
  es: {
    language: "Idioma",
    en: "Ingles",
    es: "Español",
    // menu
    home: "Inicio",
    dashboard: "Tablero",
    layoutBuilder: "Preferencias",
    usersgroupIndex: "Grupos de usuarios",
    user: "Usuarios",
    profile: "Mi perfil",
    profileOverview: "Descripción general",
    maritimeFileOverview: "Descripción general",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    permissionsIndex: "Permisos",
    usersIndex: "Gestión de usuarios",
    userCreate: "Nuevo usuario",
    userUpdate: "Editar usuario",
    companies: "Empresas",
    companiesIndex: "Gestión de empresas",
    companiesCreate: "Nueva empresa",
    companiesUpdate: "Editar empresa",
    companiesGroup: "Grupo de empresas",
    companiesGroupIndex: "Gestión de grupo de empresas",
    companiesGroupCreate: "Nuevo grupo de empresas",
    companiesGroupUpdate: "Editar grupo de empresas",
    offices: "Oficinas",
    officesIndex: "Gestión de oficinas",
    officesCreate: "Nueva oficina",
    officesUpdate: "Editar oficina",
    maritime_agency: "Agencia maritima",
    maritime_files: "Expedientes maritimas",
    maritime_file: "Expediente",
    maritime_trips: "Viajes",
    maritime_trip: "Viaje",
    measures_unit: "Unidades de Medidas",
    measures_type: "Tipo de Medidas",
    measure_unit_type: "Tipo de Unidad de Medidas",
    address_type: "Tipo de Direcciones",
    ports: "Puertos",
    docks: "Muelles",
    contacts_type: "Tipo de Contacto",
    routes_type: "Tipo de rutas",
    equipments: "Contenedores",
    equipments_type: "Tipo de equipamiento",
    equipments_subtype: "Subtipo de equipamiento",
    sectors_type: "Tipo de sector",
    transport_order_status: "Estado de la orden de transporte",
    transport_order_type: "Tipo de la orden de transporte",
    maritime_file_type: "Tipo Expediente maritimo",
    maritime_file_operations: "Tipo de Operacion Expediente maritimo",
    maritime_services: "Tipo de Servicio maritimo",
    media_type: "Tipos de medios de comunicacion",
    location_type: "Localidades",
    bookings: "Bookings",
    bills_of_lading: "Guida de carga",
    type_entities: "Tipo de Entidades",
    goods_type: "Tipo de bienes",
    entities: "Entidades",
    catalogs: "Catalogos",
    id: "Id",
    smpurposes: "Propositos de atraque",
    smrequirements: "Requerimientos de atraque",
    smoperation_type: "Tipos de operaciones",
    // end menu
    //buttons
    btnSave: "Guardar",
    btnEdit: "Editar",
    btnNew: "Nuevo",
    btnDelete: "Eliminar",
    btnCancel: "Cancelar",
    btnAdd: "Agregar",
    wait: "Por favor espere...",
    btnDetails: "Ver detalle",
    loading: "Cargando",
    //end buttons
    //inputs
    iprofile: "Perfil",
    iemail: "Correo electronico",
    ipassword: "Contraseña",
    iassignoffices: "Asignar oficinas",
    imeasureunittype: "Tipo de UM",
    iname: "Nombre",
    isize: "Medidas",
    itemperature: "Temperatura",
    imanufacturing_materials: "Materiales",
    icoordinates: "Coordenadas",
    icountry: "Pais",
    isubdivision: "Subdivisión",
    ifullcode: "Código completo",
    ifullname: "Nombre completo",
    iiso: "ISO",
    imaritime: "Maritimo",
    iborndate: "Fecha de nacimiento",
    iid: "Identificador",
    iaddress: "Dirección",
    ifiscaladdress: "Dirección fiscal",
    iphone: "Teléfono",
    ifax: "Fax",
    icif: "NIF/CIF",
    icompany: "Empresa",
    icompaniesgroup: "Grupo de empresas",
    ientity: "Entidad",
    ientityreference: "Entidad de referencia",
    ientitydata: "Datos de entidad",
    iwebsite: "Sitio web",
    istatus: "Estatus",
    istatusactive: "Activa",
    istatuscancel: "Cancelada",
    icurrentpass: "Contraseña actual",
    inewpass: "Contraseña nueva",
    iconfirmnewpass: "Confirmar contraseña nueva",
    iupdatepass: "Actualizar contraseña",
    icode: "Codigo",
    inote: "Nota",
    ifile: "Expediente",
    ifileType: "Tipo de expediente",
    ioffices: "Oficina",
    iport: "Puerto",
    idock: "Muelle",
    ivessel: "Buque",
    ishipConsignne: "Consignatario de buque",
    iconsignne: "Consignatario",
    icustomer: "Cliente",
    icontact: "Contacto",
    icheckin: "Llegada",
    icheckout: "Salida",
    ioperation: "Operación",
    iprefix: "Prefijo",
    icalls: "Scala",
    iimport: "Importacion",
    iexport: "Exportacion",
    itrip: "Viaje",
    itripType: "Tipo de viaje",
    iremarks: "Comentarios",
    ishipowner: "Armador",
    ibookingNumber: "Numero de booking",
    ibookingDate: "Fecha de booking",
    ibookingref: "Referencia",
    ishipper: "Destinatario/Remitente",
    ishipperAddress: "Destinatario/Remitente direccion",
    iActions: "Acciones",
    iBooking: "Booking",
    iDescription: "Descripcion",
    iparticipantType: "Tipo de participante",
    itype: "Tipo",
    isection: "Tramo",
    iinitialBL: "BL Inicial",
    ifinalBL: "BL Final",
    iinitialDeparture: "Partida inicial",
    ifinalDeparture: "Partida final",
    ibaseSection: "Tramo base",
    isectionIdentifier: "Id tramo",
    ismrequirement: "Requerimiento",
    ismpurpose: "Proposito",
    ismoperationType: "Operacion",
    igoodsType: "Mercancia",
    istation: "Estacion",
    inodata: "No hay datos",
    isearch: "Buscar",
    ielements: "elemento(s)",
    iconcession: "Consecion",
    izone: "Zona",
    ialignment: "Alineacion",
    iselect: "Seleccione un elemento",
    iselectdate: "Seleccione una fecha",
    ilegalname: "Nombre legal",
    iidentificationtype: "Tipo de identificacion",
    iidentification: "Identificacion",
    ilogo: "Logo",
    imanufacturingmaterials: "Materiales de produccion",
    iequipmenttype: "Tipo de equipamiento",
    iportauthority: "Autoridad portuaria",
    icustomimport: "Importacion personalizada",
    icustomexport: "Exportacion personalizada",
    iaddressrouteedi: "Direccion ruta edi",
    icolor: "Color",
    iorder: "Orden",
    idate: "Fecha",
    iblid: "BL id",
    icontainer: "Contenedor",
    iregistercontainer: "Contenedor registrado",
    igoods: "Bienes",
    iimo: "Imo",
    iregisterequipment: "Equipo registrado",
    imeasure: "Medida",
    iidshipload: "Id cargamento",
    //end inputs
    //validate
    rpassword:
      "La contraseña debe de contener al menos 4 caracteres y simbolos",
    rpasswordrequired: "La contraseña es requerida",
    rname: "El nombre es requerido",
    rmeasureunittypeid: "El tipo de unidad de medida es requerido",
    rDesc: "La descripción es requerida",
    rRemarks: "Necesita establecer un comentario",
    rcode: "El codigo es requerido",
    rshipownerid: "El armador es requerido",
    rtriptype: "El tipo de viaje es requerido",
    rconsigneeid: "El consignatario de mercancia es requerido",
    rtype: "El tipo de consignatario es requerido",
    rbasesection: "El tramo base es requerido",
    rsmrequirement: "El requerimiento es requerido",
    rsmpurpose: "El proposito es requerido",
    rdock: "El muelle es requerido",
    rcheckin: "El servicio de llegada es requerido",
    rcheckout: "El servicio de salida es requerido",
    roperationtype: "El tipo de operacion es requerido",
    rstationid: "La estacion es requerida",
    rgoodstypeid: "El tipo de mercancia es requerido",
    rofficeid: "La oficina es requerida",
    rportid: "El puerto es requerido",
    raddresstype: "El tipo de direccion es requerido",
    rshipid: "El barco es requerido",
    rmedia: "El tipo de medio de comunicacion es requerido",
    rcontacttype: "El tipo de contacto es requerido",
    rcontact: "El contacto es requerido",
    rcountry: "El pais es requerido",
    rentitytype: "El tipo de entidad es requerido",
    requipmenttype: "El tipo de equipamiento es requerido",
    requipmentsubtype: "El subtipo de equipamiento es requerido",
    requipment: "El equipamiento es requerido",
    rlocation: "La localizacion es requerida",
    rmaritimefilesoperations:
      "El fichero de operaciones maritimas es requerido",
    rmaritimefilestype: "El tipo de fichero maritimo es requerido",
    rservice: "El servicio maritimo es requerido",
    rmeasuretypeid: "El tipo de medida es requerido",
    rparticipant: "El tipo de participante es requerido",
    rroute: "El tipo de ruta es requerido",
    rsection: "El tipo de seccion es requerido",
    rtransportorderstatus: "El estado de la orden de transporte es requerido",
    rtransportordertype: "El tipo de orden de transporte es requerido",
    riso: "El ISO es requerido",
    rmaritime: "Maritimo es requerido",
    ruser: "El nombre de usuario es requerido",
    rcustomer: "El cliente es requerido",
    rentity: "El campo es requerido",
    rmaritimevoyage: "El viaje es requerido",
    rmteditabletable:
      "Para adicionar un nuevo viaje, los datos de la tabla deben ser validos",
    rgceditabletable:
      "Para adicionar un nuevo consignatario, los datos de la tabla deben ser validos",
    rsmeditabletable:
      "Para adicionar un nuevo atraque, los datos de la tabla deben ser validos",
    rmoeditabletable:
      "Para adicionar una nueva operacion de atraque, los datos de la tabla deben ser validos",
    rmaritimefiletables:
      "Para adicionar un nuevo expediente maritimo, debe existir al menos un elemento de: viajes, consignatarios de mercancias, atraques y operaciones de atraque",
    rbkeditabletable:
        "Para adicionar un nuevo booking, los datos de la tabla deben ser validos",
    rtseditabletable:
        "Para adicionar un nuevo transbordo, los datos de la tabla deben ser validos",
    rbreditabletable:
        "Para adicionar una nueva ruta de booking, los datos de la tabla deben ser validos",
    rpeditabletable:
        "Para adicionar un nuevo participante, los datos de la tabla deben ser validos",
    rbleditabletable:
        "Para adicionar una nueva guia de carga, los datos de la tabla deben ser validos",
    rsleditabletable:
        "Para adicionar un nuevo cargamento, los datos de la tabla deben ser validos",
    rgfeditabletable:
        "Para adicionar un nuevo bien, los datos de la tabla deben ser validos",
    rbooking:
        "Para adicionar un nuevo booking, debe existir al menos un elemento de: booking, transbordo, rutas del booking, participantes y guia de carga",
    //end validate
    //labels
    lpermisionsassigned: "Permisos asignados",
    //end labels
    //titles
    timaritimefilelist: "Expedientes maritimos",
    timaritimetripslist: "Listado de viajes maritimos",
    tisummarystatement: "Declaracion sumaria",
    timaritimefile: "Expediente maritimo",
    titrips: "Viajes",
    tibookings: "Booking",
    tcalls: "Escala",
    troute: "Rutas",
    tmoorings: "Atraque",
    tconsignees: "Consignatarios de Mercancias",
    tmooringoperation: "Operaciones en el atraque",
    tiBillOfLadings: "Guia de carga",
    tiEntityTypes: "Tipo de entidades",
    toperations: "Operaciones",
    ttransshipment: "Transbordo",
    tbookingroute: "Rutas del booking",
    tparticipants: "Participantes",
    tshipsloads: "Cargamentos",

    //endtitles
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signOut: "Cerrar sesion",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    createNewBusinessGroup: "Crear nuevo grupo de empresas",
    createNewOfficeGroup: "Crear nuevo grupo de oficinas",
    createNewBusiness: "Crear nueva empresa",

    //modal titles
    mt_maritime_file_add: "Agregar archivo marítimo",
    mt_maritime_file_edit: "Modificar archivo marítimo",
    mt_maritime_services_add: "Agregar tipo de servicio marítimo",
    mt_maritime_services_edit: "Modificar tipo de servicio marítimo",
    mt_address_type_add: "Agregar tipo de dirección",
    mt_address_type_edit: "Modificar tipo de dirección",
    mt_port_add: "Agregar puerto",
    mt_port_edit: "Editar puerto",
    mt_docks_add: "Agregar muelle",
    mt_docks_edit: "Editar muelle",
    mt_measure_type_add: "Agregar tipo de medida",
    mt_measure_type_edit: "Modificar tipo de medida",
    mt_measure_unit_edit: "Modificar unidad de medida",
    mt_measure_unit_add: "Agregar unidad de medida",
    mt_measure_type_unit_edit: "Modificar tipo de unidad de medida",
    mt_measure_type_unit_add: "Agregar tipo de unidad de medida",
    mt_contact_type_edit: "Modificar tipo de contacto",
    mt_contact_type_add: "Agregar tipo de contacto",
    mt_transport_order_status_edit:
      "Modificar estado de la orden de transporte",
    mt_transport_order_status_add: "Agregar estado de la orden de transporte",
    mt_transport_order_type_edit: "Modificar tipo de la orden de transporte",
    mt_transport_order_type_add: "Agregar tipo de la orden de transporte",
    mt_route_type_edit: "Modificar tipo de ruta",
    mt_route_type_add: "Agregar tipo de ruta",
    mt_equipment_type_edit: "Modificar tipo de equipamiento",
    mt_equipment_type_add: "Agregar tipo de equipamiento",
    mt_equipment_subtype_edit: "Modificar subtipo de equipamiento",
    mt_equipment_subtype_add: "Agregar subtipo de equipamiento",
    mt_sector_type_edit: "Modificar tipo de sector",
    mt_sector_type_add: "Agregar tipo de sector",
    mt_maritime_file_operation_edit: "Modificar operación",
    mt_maritime_file_operation_add: "Agregar operación",
    mt_media_edit: "Modificar tipo de medio de comunicacion",
    mt_media_add: "Agregar tipo de medio de comunicacion",
    mt_location_edit: "Modificar localización",
    mt_location_add: "Agregar localización",
    mt_entity_type_edit: "Modificar tipo de entidad",
    mt_entity_type_add: "Agregar tipo de entidad",
    mt_entity_edit: "Modificar entidad",
    mt_entity_add: "Agregar entidad",
    mt_goods_type_edit: "Modificar tipo de bienes",
    mt_goods_type_add: "Agregar tipo de bienes",
    mt_smpurposes_edit: "Modificar proposito de atraque",
    mt_smpurposes_add: "Agregar proposito de atraque",
    mt_smrequirements_edit: "Modificar requerimiento de atraque",
    mt_smrequirements_add: "Agregar requerimiento de atraque",
    mt_equipment_edit: "Modificar equipamiento",
    mt_equipment_add: "Agregar equipamiento",
    mt_add_mf_booking_route: "Agregar ruta a booking",
    mt_add_mf_booking_equipment: "Agregar equipamiento al booking",
    mt_maritime_trip_edit: "Editar viaje",
    mt_maritime_trip_add: "Adicionar viaje",
    mt_good_consignee_edit: "Editar consignatario",
    mt_good_consignee_add: "Adicionar consignatario",
    mt_ship_mooring_edit: "Editar atraque",
    mt_ship_mooring_add: "Adicionar atraque",
    mt_mooring_operation_edit: "Editar operacion de atraque",
    mt_mooring_operation_add: "Adicionar operacion de atraque",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "es",
  globalInjection: true,
  messages,
});

export default i18n;
