const DocMenuConfig: Record<string, any> = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon:
          document.location.origin +
          "/media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
      },
      // PROFILE
      {
        svgIcon:
          document.location.origin + "/media/icons/duotone/General/User.svg",
        sectionTitle: "profile",
        route: "/profile",
        sub: [
          {
            heading: "accountOverview",
            route: "/profile/overview",
          },
          {
            heading: "settings",
            route: "/profile/settings",
          },
          {
            heading: "layoutBuilder",
            route: "/profile/builder",
            svgIcon:
              document.location.origin +
              "/media/icons/duotone/Interface/Settings-02.svg",
            fontIcon: "bi-layers",
          },
        ],
      },
    ],
  },
  // GENERAL
  {
    pages: [
      // Companies
      {
        svgIcon:
            document.location.origin + "/media/icons/duotone/Home/Home2.svg",
        sectionTitle: "general",
        sub: [
          {
            sectionTitle: "companies",
            route: "/companies/index",
            sub: [
              {
                heading: "companiesGroupIndex",
                route: "/companies/companiesgroup/index",
                permission: "companies group: index",
              },
              {
                heading: "companiesIndex",
                route: "/companies/index",
                permission: "companies: index",
              },
              {
                heading: "officesIndex",
                route: "/companies/offices/index",
                permission: "offices: index",
              },
            ],
          },
          {
            svgIcon:
                document.location.origin + "/media/icons/duotone/General/User.svg",
            sectionTitle: "user",
            route: "/users",
            sub: [
              {
                heading: "usersgroupIndex",
                route: "/usersgroup",
                permission: "roles: index",
              },
              {
                heading: "usersIndex",
                route: "/users",
                permission: "users: index",
              },
            ],
          },
          {
            svgIcon:
                document.location.origin +
                "/media/icons/duotone/Communication/Add-user.svg",
            sectionTitle: "entities",
            sub: [
              {
                heading: "entities",
                route: "/entities",
                permission: "entities: index",
              },
            ],
          },
          {
            svgIcon:
                document.location.origin + "/media/icons/duotone/Home/Home-heart.svg",
            sectionTitle: "catalogs",
            route: "catalogs",
            sub: [
              {
                heading: "type_entities",
                route: "/type-entities",
                permission: "type entities: index",
              },
              {
                heading: "address_type",
                route: "/address_type",
                permission: "addresses: index",
              },
              {
                heading: "measures_unit",
                route: "/measures_unit",
                permission: "measure unit: index",
              },
              {
                heading: "measures_type",
                route: "/measures_type",
                permission: "measures type: index",
              },
              {
                heading: "measure_unit_type",
                route: "/measure_unit_type",
                permission: "measure unit type: index",
              },
              {
                heading: "contacts_type",
                route: "/contacts_type",
                permission: "contacts type: index",
              },
              {
                heading: "transport_order_status",
                route: "/transport_order_status",
                permission: "transport order status: index",
              },
              {
                heading: "transport_order_type",
                route: "/transport_order_type",
                permission: "transport order type: index",
              },
              {
                heading: "maritime_file_type",
                route: "/maritime_file_type",
                permission: "maritime file type: index",
              },
              {
                heading: "routes_type",
                route: "/routes_type",
                permission: "routes type: index",
              },
              {
                heading: "equipments",
                route: "/equipments",
                permission: "equipments: index",
              },
              {
                heading: "equipments_type",
                route: "/equipments_type",
                permission: "equipments type: index",
              },
              {
                heading: "equipments_subtype",
                route: "/equipments_subtype",
                permission: "equipments subtype: index",
              },
              {
                heading: "sectors_type",
                route: "/sectors_type",
                permission: "sectors type: index",
              },
              {
                heading: "maritime_file_operations",
                route: "/maritime_file_operations",
                permission: "maritime file operations: index",
              },
              {
                heading: "maritime_services",
                route: "/maritime_services",
                permission: "maritime services: index",
              },
              {
                heading: "ports",
                route: "/ports",
                permission: "ports: index",
              },
              {
                heading: "media_type",
                route: "/media_type",
                permission: "media type: index",
              },
              {
                heading: "location_type",
                route: "/location_type",
                permission: "location type: index",
              },
              {
                heading: "goods_type",
                route: "/goods_type",
                permission: "goods type: index",
              },
              {
                heading: "smpurposes",
                route: "/smpurposes",
                permission: "smpurposes: index",
              },
              {
                heading: "smrequirements",
                route: "/smrequirements",
                permission: "smrequirements: index",
              },
              {
                heading: "smoperation_type",
                route: "/smoperation_type",
                permission: "smoperation type: index",
              },
              {
                heading: "docks",
                route: "/docks",
                permission: "docks: index",
              },
            ],
          },
        ],

      },
    ],
  },
  // MARITIME AGENCY

  {
    pages: [
      // Companies
      {
        svgIcon:
            document.location.origin + "/media/icons/duotone/Home/Home-heart.svg",
        sectionTitle: "maritime_agency",
        sub: [
          {
            svgIcon:
                document.location.origin + "/media/icons/duotone/Home/Home-heart.svg",
            sectionTitle: "maritime_files",
            sub: [
              {
                heading: "maritime_file",
                route: "/maritimefiles",
                permission: "maritime files: index",
              },
              /* {
                heading: "maritime_trips",
                route: "/maritime-trips/index",
                permission: "maritime trips: index"
              },
              {
                heading: "bookings",
                route: "/bookings/index",
                permission: "bookings: index"
              },
              {
                heading: "bills_of_lading",
                route: "/bills_of_lading/index",
                permission: "bill of lading: index"
              } */
            ],
          },
        ],

      },
    ],
  },

  {
    route: "/apps",
    pages: [
      {
        sectionTitle: "chat",
        route: "/chat",
        svgIcon:
          document.location.origin +
          "/media/icons/duotone/Communication/Group-chat.svg",
        fontIcon: "bi-chat-left",
        sub: [
          {
            heading: "privateChat",
            route: "/apps/chat/private-chat",
          },
          {
            heading: "groupChat",
            route: "/apps/chat/group-chat",
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
