import ApiService from "@/core/services/ApiService";
import StorageService from "@/core/services/StorageService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { permissions } from "@/core/helpers/functions";

@Module
export default class GeneralModule extends VuexModule {
  localSearch = "";
  permissionslist = [];
  menusList = [];
  /**
   * Get string for search
   * @returns text
   */
  get searchLocal(): string {
    return this.localSearch || "";
  }

  // my permissions
  get permissions(): Record<string, any> {
    return this.permissionslist;
  }

  get menus(): Record<string, any> {
    return this.menusList;
  }

  @Mutation
  ["setSearchLocal"](text) {
    this.localSearch = text;
  }

  @Mutation
  ["setPermissions"](permissions) {
    StorageService.savePermissions(permissions);
    this.permissionslist = permissions;
  }

  @Mutation
  ["resetPermissions"]() {
    this.permissionslist = [];
    this.menusList = [];
  }

  @Mutation
  ["setMenu"](menus) {
    StorageService.saveMenus(menus);
    this.menusList = menus;
  }

  @Action
  ["myPermissions"]() {
    ApiService.get("/api/permissions/me")
      .then(({ data }) => {
        this.context.commit("setPermissions", data);
        return data;
      })
      .then(() => {
        this.context.dispatch("myMenu");
      })
      .catch(() => {
        console.error("error al obtener los permisos");
      });
  }

  @Action
  ["myMenu"]() {
    permissions()
      .then((menus) => {
        this.context.commit(
          "setMenu",
          menus.filter((ele) => !ele.pages || ele.pages.length > 0)
        );
      })
      .catch(() => {
        console.error("error al setear los permisos");
      });
  }
}
