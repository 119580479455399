import ApiService from "@/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface MaritimeTrip {
  id?: number;
  maritime_file_id?: number;
  code: string;
  shipowner_id: string;
  trip_type: string;
  remarks: string;
}

interface GoodsConsignee {
  id?: number;
  maritime_file_id?: number;
  consignee_id: string;
  type: string;
  sections: number;
  initial_bl?: number;
  final_bl?: number;
  initial_departure?: number;
  final_departure?: number;
  base_section?: number;
  section_identificator?: number;
}

interface ShipMooring {
  id?: number;
  maritime_file_id?: number;
  requirement_id: string;
  purpose_id: string;
  dock_id: string;
  check_in: string;
  check_out: string;
}

interface MooringOperation {
  id?: number;
  ship_mooring_id?: number;
  operation_type_id: string;
  station_id: string;
  goods_type_id: string;
}

interface Booking {
  id?: number;
  maritime_voyage_id: number;
  trip_type_id: string;
  booking_number: string;
  booking_date: string;
  reference: string;
}

interface Transshipment {
  id?: number;
  booking_id: number;
  maritime_voyage_id: number;
  remarks: string;
}

interface BookingRoute {
  id?: number;
  booking_id: number;
  route_type_id: number;
  location_id?: number;
  order: string;
  remarks: string;
}

interface Participant {
  id?: number;
  booking_id: number;
  entity_id?: number;
  participant_type_id: number;
  entity_data: string;
}

interface BillOfLanding {
  id?: number;
  booking_id: number;
  bl_date: string;
}

interface Shipload {
  id?: number;
  booking_id: number;
  bl_id?: number;
  container?: number;
  registered_container?: number;
  goods: string;
  remarks: string;
  imo: string;
  registered_equipments?: number;
}

interface GoodFeature {
  id?: number;
  shipload_id: number;
  measure_type_id: number;
  measure_unit_id: number;
  measure: string;
  remarks: string;
}

@Module
export default class MaritimeFileModule extends VuexModule {
  maritimeFileId: undefined;
  maritimeFile: undefined;
  maritimeTripsList: MaritimeTrip[] = [];
  goodsConsigneesList: GoodsConsignee[] = [];
  shipMooringsList: ShipMooring[] = [];
  mooringOperationsList: MooringOperation[] = [];

  bookingsList: Booking[] = [];
  transshipmentsList: Transshipment[] = [];
  bookingRouteList: BookingRoute[] = [];
  participantList: Participant[] = [];
  billOfLandingList: BillOfLanding[] = [];
  shiploadList: Shipload[] = [];
  goodList: GoodFeature[] = [];

  get maritimeId(): any {
    return this.maritimeFileId;
  }

  get maritimeFileItem(): any {
    return this.maritimeFile;
  }

  get MaritimeTrips(): MaritimeTrip[] {
    return this.maritimeTripsList;
  }

  get GoodsConsignees(): GoodsConsignee[] {
    return this.goodsConsigneesList;
  }

  get ShipMoorings(): ShipMooring[] {
    return this.shipMooringsList || "";
  }

  get MooringOperations(): MooringOperation[] {
    return this.mooringOperationsList || "";
  }

  get Bookings(): Booking[] {
    return this.bookingsList || "";
  }

  get Transshipments(): Transshipment[] {
    return this.transshipmentsList || "";
  }

  get BookingRoutes(): BookingRoute[] {
    return this.bookingRouteList || "";
  }

  get Participants(): Participant[] {
    return this.participantList || "";
  }

  get BillsOfLanding(): BillOfLanding[] {
    return this.billOfLandingList || "";
  }

  get Shiploads(): Shipload[] {
    return this.shiploadList || "";
  }

  get Goods(): GoodFeature[] {
    return this.goodList || "";
  }

  @Mutation
  ["setMaritimeFileId"](payload) {
    this.maritimeFileId = payload;
  }

  @Mutation
  ["setMaritimeFile"](payload) {
    this.maritimeFile = payload;
  }

  @Mutation
  ["setMaritimeTrip"](payload) {
    this.maritimeTripsList.push(payload);
  }

  @Mutation
  ["removeMaritimeTrip"](index) {
    this.maritimeTripsList.splice(index, 1);
  }

  @Mutation
  ["setGoodsConsignees"](payload) {
    this.goodsConsigneesList.push(payload);
  }

  @Mutation
  ["removeGoodsConsignees"](index) {
    this.goodsConsigneesList.splice(index, 1);
  }

  @Mutation
  ["setShipMooring"](payload) {
    this.shipMooringsList.push(payload);
  }

  @Mutation
  ["removeShipMooring"](index) {
    this.shipMooringsList.splice(index, 1);
  }

  @Mutation
  ["setMooringOperation"](payload) {
    this.mooringOperationsList.push(payload);
  }

  @Mutation
  ["removeMooringOperation"](index) {
    this.mooringOperationsList.splice(index, 1);
  }

  @Mutation
  ["setBooking"](payload) {
    this.bookingsList.push(payload);
  }

  @Mutation
  ["removeBooking"](index) {
    this.bookingsList.splice(index, 1);
  }

  @Mutation
  ["setTransshipment"](payload) {
    this.transshipmentsList.push(payload);
  }

  @Mutation
  ["removeTransshipment"](index) {
    this.transshipmentsList.splice(index, 1);
  }

  @Mutation
  ["setBookingRoute"](payload) {
    this.bookingRouteList.push(payload);
  }

  @Mutation
  ["removeBookingRoute"](index) {
    this.bookingRouteList.splice(index, 1);
  }

  @Mutation
  ["setParticipant"](payload) {
    this.participantList.push(payload);
  }

  @Mutation
  ["removeParticipant"](index) {
    this.participantList.splice(index, 1);
  }

  @Mutation
  ["setBillOfLanding"](payload) {
    this.billOfLandingList.push(payload);
  }

  @Mutation
  ["removeBillOfLanding"](index) {
    this.billOfLandingList.splice(index, 1);
  }

  @Mutation
  ["setShipload"](payload) {
    this.shiploadList.push(payload);
  }

  @Mutation
  ["removeShipload"](index) {
    this.shiploadList.splice(index, 1);
  }

  @Mutation
  ["setGood"](payload) {
    this.goodList.push(payload);
  }

  @Mutation
  ["removeGood"](index) {
    this.goodList.splice(index, 1);
  }

  @Mutation
  ["resetModule"]() {
    this.maritimeFileId = undefined;
    this.maritimeFile = undefined;
    this.maritimeTripsList = [];
    this.goodsConsigneesList = [];
    this.shipMooringsList = [];
    this.mooringOperationsList = [];

    this.bookingsList = [];
    this.transshipmentsList = [];
    this.bookingRouteList = [];
    this.participantList = [];
    this.billOfLandingList = [];
    this.shiploadList = [];
    this.goodList = [];
  }
}
