import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { hasPermission } from "@/core/helpers/functions";
import JwtService from "@/core/services/JwtService";
import TypeEntities from "@/views/catalog/type_entities/TypeEntities.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/website",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      authorization: true,
    },
    children: [
      // sign-in
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/layout/Layout.vue"),
        meta: {
          authorization: true,
        },
      },
      // dashboard
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      // end dashboard

      // profile
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/Index.vue"),
        children: [
          {
            path: "overview",
            name: "ProfileOverview",
            component: () => import("@/views/profile/Overview.vue"),
          },
          {
            path: "settings",
            name: "profile-settings",
            component: () => import("@/views/profile/Settings.vue"),
          },
          {
            path: "builder",
            name: "builder",
            component: () => import("@/views/profile/Builder.vue"),
          },
        ],
      },
      // end profile

      //users group or roles
      {
        path: "/usersgroup",
        name: "usersgroup",
        component: () => import("@/views/users/UsersGroup.vue"),
        meta: {
          permission: "roles: index",
        },
      },
      //users group or roles

      // users
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/users/Users.vue"),
        meta: {
          permission: "users: index",
        },
      },
      {
        path: "/user/:user_id",
        name: "userProfile",
        component: () => import("@/views/profile/Index.vue"),
        meta: {
          permission: "users: show",
        },
        children: [
          {
            path: "overview",
            name: "user-profile-overview",
            component: () => import("@/views/profile/Overview.vue"),
          },
          {
            path: "settings",
            name: "user-profile-settings",
            component: () => import("@/views/profile/Settings.vue"),
          },
        ],
      },
      // end users

      // companies
      {
        path: "/companies",
        name: "companies",
        component: () => import("@/views/companies/Index.vue"),
        children: [
          {
            path: "index",
            name: "companiesIndex",
            component: () => import("@/views/companies/Companies.vue"),
            meta: {
              permission: "companies: index",
            },
          },
          {
            path: "create",
            name: "companiesCreate",
            component: () => import("@/views/companies/CompaniesCU.vue"),
            meta: {
              permission: "create_company",
            },
          },
          {
            path: "update",
            name: "companiesUpdate",
            component: () => import("@/views/companies/CompaniesCU.vue"),
            meta: {
              permission: "update_company",
            },
          },
          {
            path: "companiesgroup",
            name: "companiesGroup",
            component: () => import("@/views/companies/Index.vue"),
            children: [
              {
                path: "index",
                name: "companiesGroupIndex",
                component: () => import("@/views/companies/CompaniesGroup.vue"),
                meta: {
                  permission: "companies group: index",
                },
              },
            ],
          },
          {
            path: "offices",
            name: "offices",
            component: () => import("@/views/companies/Index.vue"),
            children: [
              {
                path: "index",
                name: "officesIndex",
                component: () => import("@/views/companies/Offices.vue"),
                meta: {
                  permission: "offices: index",
                },
              },
            ],
          },
          // end oficces crud
        ],
      },
      // end companies

      //Catalogs
      {
        path: "/type-entities",
        name: "typeEntities",
        component: TypeEntities,
        meta: {
          permission: "type entities: index",
        },
      },
      {
        path: "/entities",
        name: "entities",
        component: () => import("@/views/catalog/entities/Entities.vue"),
        meta: {
          permission: "entities: index",
        },
      },
      {
        path: "/address_type",
        name: "address_type",
        component: () => import("@/views/catalog/address_type/AddressType.vue"),
        meta: {
          permission: "addresses: index",
        },
      },
      {
        path: "/measures_unit",
        name: "measures_unit",
        component: () => import("@/views/catalog/measure_unit/MeasureUnit.vue"),
        meta: {
          permission: "measure unit: index",
        },
      },
      {
        path: "/measures_type",
        name: "measures_type",
        component: () =>
          import("@/views/catalog/measures_type/MeasuresType.vue"),
        meta: {
          permission: "measures type: index",
        },
      },
      {
        path: "/contacts_type",
        name: "contacts_type",
        component: () =>
          import("@/views/catalog/contacts_type/ContactsType.vue"),
        meta: {
          permission: "contacts type: index",
        },
      },
      {
        path: "/transport_order_status",
        name: "transport_order_status",
        component: () =>
          import(
            "@/views/catalog/transport_order_status/TransportOrderStatus.vue"
          ),
        meta: {
          permission: "transport order status: index",
        },
      },
      {
        path: "/transport_order_type",
        name: "transport_order_type",
        component: () =>
          import("@/views/catalog/transport_order_type/TransportOrderType.vue"),
        meta: {
          permission: "transport order type: index",
        },
      },
      {
        path: "/maritime_file_type",
        name: "maritime_file_type",
        component: () =>
          import("@/views/catalog/maritime_file_type/MaritimeFileType.vue"),
        meta: {
          permission: "maritime file type: index",
        },
      },
      {
        path: "/routes_type",
        name: "routes_type",
        component: () => import("@/views/catalog/routes_type/RoutesType.vue"),
        meta: {
          permission: "routes type: index",
        },
      },
      {
        path: "/equipments_type",
        name: "equipments_type",
        component: () =>
          import("@/views/catalog/equipment_type/EquipmentsType.vue"),
        meta: {
          permission: "equipments type: index",
        },
      },
      {
        path: "/equipments",
        name: "equipments",
        component: () => import("@/views/catalog/equipment/Equipments.vue"),
        meta: {
          permission: "equipments: index",
        },
      },
      {
        path: "/equipments_subtype",
        name: "equipments_subtype",
        component: () =>
          import("@/views/catalog/equipment_subtypes/EquipmentsSubType.vue"),
        meta: {
          permission: "equipments subtype: index",
        },
      },
      {
        path: "/sectors_type",
        name: "sectors_type",
        component: () => import("@/views/catalog/sectors_type/SectorsType.vue"),
        meta: {
          permission: "sectors type: index",
        },
      },
      {
        path: "/maritime_file_operations",
        name: "maritime_file_operations",
        component: () =>
          import(
            "@/views/catalog/maritime_file_operations/MaritimeFileOperations.vue"
          ),
        meta: {
          permission: "maritime file operations: index",
        },
      },
      {
        path: "/measure_unit_type",
        name: "measure_unit_type",
        component: () =>
          import("@/views/catalog/measure_unit_type/MeasureUnitType.vue"),
        meta: {
          permission: "measure unit type: index",
        },
      },
      {
        path: "/media_type",
        name: "media_type",
        component: () => import("@/views/catalog/media_type/MediaType.vue"),
        meta: {
          permission: "media type: index",
        },
      },
      {
        path: "/location_type",
        name: "location_type",
        component: () =>
          import("@/views/catalog/location_type/LocationType.vue"),
        meta: {
          permission: "location type: index",
        },
      },
      {
        path: "/goods_type",
        name: "goods_type",
        component: () => import("@/views/catalog/goods_type/GoodsType.vue"),
        meta: {
          permission: "goods type: index",
        },
      },
      {
        path: "/smpurposes",
        name: "smpurposes",
        component: () => import("@/views/catalog/smpurposes/SMPurposes.vue"),
        meta: {
          permission: "smpurposes: index",
        },
      },
      {
        path: "/smrequirements",
        name: "smrequirements",
        component: () =>
          import("@/views/catalog/smrequirements/SMRequirements.vue"),
        meta: {
          permission: "smrequirements: index",
        },
      },
      {
        path: "/smoperation_type",
        name: "smoperation_type",
        component: () =>
          import("@/views/catalog/smoperation_types/SMOperationTypes.vue"),
        meta: {
          permission: "smoperation type: index",
        },
      },
      {
        path: "/docks",
        name: "docks",
        component: () => import("@/views/catalog/docks/Docks.vue"),
        meta: {
          permission: "docks: index",
        },
      },
      {
        path: "/maritime_services",
        name: "maritime_services",
        component: () =>
          import("@/views/catalog/maritime_services/MaritimeServices.vue"),
        meta: {
          permission: "maritime services: index",
        },
      },
      {
        path: "/ports",
        name: "ports",
        component: () => import("@/views/catalog/ports/Ports.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      //End catalogs

      //Maritime file
      {
        path: "/maritimefiles",
        name: "maritimeFiles",
        component: () => import("@/views/maritime_file/MaritimeFile.vue"),
        meta: {
          permission: "maritime files: index",
        },
      },
      {
        path: "/maritimefile",
        name: "maritimefileCreate",
        component: () => import("@/views/maritime_file/MaritimeFileForm.vue"),
        meta: {
          permission: "maritime files: create",
        },
      },
      {
        path: "/maritimefile/:id",
        name: "maritimefileEdit",
        component: () => import("@/views/maritime_file/MaritimeFileForm.vue"),
        meta: {
          permission: "maritime files: update",
        },
      },
      {
        path: "/maritimefile/:id/trips",
        name: "mf_trips",
        component: () => import("@/views/maritime_file/Trips.vue"),
        meta: {
          permission: "maritime trips: index",
        },
      },
      {
        path: "/maritimefile/:id/bookings",
        name: "mf_bookings",
        component: () => import("@/views/maritime_file/Bookings.vue"),
        meta: {
          permission: "bookings: index",
        },
      },
      {
        path: "/maritimefile/:id/routes",
        name: "troute",
        component: () => import("@/views/maritime_file/Routes.vue"),
        meta: {
          permission: "bookings: index",
        },
      },
      {
        path: "/maritimefile/:id/moorings",
        name: "mf_moorings",
        component: () => import("@/views/maritime_file/Moorings.vue"),
        meta: {
          permission: "bookings: index",
        },
      },
      {
        path: "/maritimefile/:id/bl",
        name: "mf_bl",
        component: () => import("@/views/maritime_file/MFBillOfLading.vue"),
        meta: {
          permission: "bookings: index",
        },
      },
      //End maritime file

      //Maritime Trips
      {
        path: "/maritime-trips",
        name: "maritime-trips",
        component: () => import("@/views/maritime_file/trips/Index.vue"),
        children: [
          {
            path: "index",
            name: "maritimeTripsIndex",
            component: () =>
              import("@/views/maritime_file/trips/MaritimeTrips.vue"),
            meta: {
              permission: "maritime trips: index",
            },
          },
          {
            path: "create",
            name: "maritimeTripsCreate",
            component: () =>
              import("@/views/maritime_file/trips/MaritimeTripForm.vue"),
            meta: {
              permission: "maritime trips: create",
            },
          },
          {
            path: "edit/:id",
            name: "maritime-trips-edit",
            component: () =>
              import("@/views/maritime_file/trips/MaritimeTripForm.vue"),
            meta: {
              permission: "maritime trips: update",
            },
          },
        ],
      },
      //End maritime trips

      //Bookings
      {
        path: "/bookings",
        name: "bookings",
        component: () => import("@/views/maritime_file/bookings/Index.vue"),
        children: [
          {
            path: "index",
            name: "bookingsIndex",
            component: () =>
              import("@/views/maritime_file/bookings/bookings.vue"),
            meta: {
              permission: "bookings: index",
            },
          },
          {
            path: "create",
            name: "bookingCreate",
            component: () =>
              import("@/views/maritime_file/bookings/BookingForm.vue"),
            meta: {
              permission: "bookings: create",
            },
          },
          {
            path: "edit/:id",
            name: "bookingEdit",
            component: () =>
              import("@/views/maritime_file/bookings/BookingForm.vue"),
            meta: {
              permission: "bookings: update",
            },
          },
        ],
      },
      //End Bookings

      //bill of lading
      {
        path: "/bills_of_lading",
        name: "bills_of_lading",
        component: () =>
          import("@/views/maritime_file/bill_of_ladings/Index.vue"),
        children: [
          {
            path: "index",
            name: "blIndex",
            component: () =>
              import("@/views/maritime_file/bill_of_ladings/BillOfLadings.vue"),
            meta: {
              permission: "bill of landings: index",
            },
          },
          {
            path: "create",
            name: "blCreate",
            component: () =>
              import(
                "@/views/maritime_file/bill_of_ladings/BillOfLadingsForm.vue"
              ),
            meta: {
              permission: "bill of landings: create",
            },
          },
          {
            path: "edit/:id",
            name: "blEdit",
            component: () =>
              import(
                "@/views/maritime_file/bill_of_ladings/BillOfLadingsForm.vue"
              ),
            meta: {
              permission: "bill of landings: update",
            },
          },
        ],
      },
      //bill of lading

      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/chat/Chat.vue"),
      },
    ],
  },
  {
    path: "/website",
    name: "website",
    component: () => import("@/views/website/Website.vue"),
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue"),
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  // console.log(from.path, to.path);
  // store.dispatch(Actions.VERIFY_AUTH);
  const denied = !hasPermission(to.meta.permission as string);
  if (from.path !== "/" && to.meta.permission && denied) {
    router.push({ name: "403" });
  }

  if (!JwtService.getToken() && to.meta.authorization) {
    store.commit("logOut");
    router.push("website");
  }
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
